import * as React from 'react'
import * as styles from './gengar-halloween.module.scss'

const GengarHalloween = () => {
  return(
    <div className={styles.contain}>
      <div className={styles.head}>
        <div className={styles.eyes}>
          <div className={styles.eye}></div>
          <div className={styles.eye}></div>
        </div>
        <div className={styles.mouth}></div>
      </div>
    </div>
  )
}

export default GengarHalloween