import * as React from "react";
import Seo from "../../../components/seo";
import Layout from "../../../components/layout";
import ArtBoard from "../../../components/ArtBoard";
import GengarHalloween from "../../../components/cssart/gengar-halloween";

const CssArt = () => {
  return (
    <Layout pageTitle="CSS Art: Catch Gengar!">
      <Seo title="Catch Gengar! - CSS ART" description="Catch Gengar! on CSS" />
      <ArtBoard link="https://codepen.io/amayadoring/pen/YzxrWrv">
        <GengarHalloween />
      </ArtBoard>
    </Layout>
  );
};

export default CssArt;
